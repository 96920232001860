var reloadIndex = 0

// If we have an input validation error on the server end, we don't
// want to show the spinner.
var invalidInput = false

// Don't allow the submit to be clicked if it's alreaddy been clicked
var waitingForResponse = false

const fields = [
  // abstracted here in case we want to add more fields later.
  "email",
  "website"
]

const params = {
  data: new URLSearchParams(document.location.search),
  get: key => params.data.get(key),
  email: null,
  website: null,
}

fields.forEach(el => {
  // populate the URLSearchParam values
  params[el] = params.get(el)
})

document.addEventListener('DOMContentLoaded', function (event) {
  const elements = {
    email: document.getElementById('email'),
    website: document.getElementById('website'),
    spinner: document.getElementById('spinner'),
    error: document.getElementById('serverresponse'),
    form: document.getElementById('eeitool'),
  };

  const xhttp = new XMLHttpRequest()

  // If we haven't received the form URL parameters, do nothing
  if (params.email == null || params.website == null) return

  fields.forEach(el => {
    // Prepopulate form fields.
    if (params[el] !== null) {
      elements[el].value = decodeURIComponent(params[el])
    }
  })

  grecaptcha.ready(function () {
    grecaptcha
      .execute('6LejjdojAAAAAANyvR9cFTkxbhVg3o-6K9kinBt3', { action: 'submit' })
      .then(function (token) {
        // Don't allow the submit to be clicked if it's alreaddy been clicked
        if (waitingForResponse == true) return;

        // let serverURL = "https://eei.sitback.com.au/co2";
        // ...or for local testing
        let serverURL = 'http://localhost:3000/co2';
        console.log('Sending request to ' + serverURL);

        // Construct message
        let jsonRequest = JSON.stringify({
          website: elements.website.value,
          email: elements.email.value,
          'g-recaptcha-response': token,
        });
        console.log('Request is ' + jsonRequest);

        // Send the JSON request to the report server
        xhttp.open('POST', serverURL, true);
        xhttp.setRequestHeader('Content-type', 'application/json');
        xhttp.send(jsonRequest);

        invalidInput = false;
        waitingForResponse = true;

        // Hide the error message if this is a retry
        elements.error.classList.add('inactive');

        // Lets wait a second before showing the spinner because we might get
        // an input validation error
        setTimeout(() => {
          // Exit early if there was an input validation error
          if (invalidInput == true) {
            invalidInput == false;
            waitingForResponse = false;
            return false;
          }

          // Show the spinner wrapper
          elements.spinner.classList.remove('inactive');

          // Hide the form
          elements.form.classList.add('inactive');
        }, '1000'); // setTimeout

        return false;
      }); // grecaptcha.execute
  }); // grecaptcha.ready(function

  // Handle the server response
  xhttp.onreadystatechange = function () {
    if (xhttp.readyState === XMLHttpRequest.DONE) {
      if (this.status == 200) {
        // Report completed successfully, so go to the report URL
        let reportURL = JSON.parse(this.responseText).reportURL;
        window.location.href = reportURL;
      } else {
        // Oops, an error occurred
        let status = this.status;
        console.log('Error occurred. Response was:', this);

        invalidInput = true;
        waitingForResponse = false;

        // Unhide the form
        elements.form.classList.remove('inactive');

        // Parse out the error message
        let defaultErrorMessage = 'Service currently unavailable. Please try again later.';
        let errorMessage;
        if (status > 0 && this.response != null) {
          try {
            let errorMessageJSON = JSON.parse(this.response);
            errorMessage = errorMessageJSON.error.message;
          } catch (error) {
            // The response body wasn't JSON, probably because CloudFront gave
            // us an HTML error message, so we'll just use the default one.
            errorMessage = defaultErrorMessage;
          }
        } else {
          errorMessage = defaultErrorMessage;
        }

        // Hide the spinner
        elements.spinner.classList.add('inactive');

        // Show the error message
        elements.error.innerHTML = errorMessage;
        elements.error.classList.remove('inactive');
      }
    }
  };
});
